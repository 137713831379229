.item {
  width: 1170px;
  min-height: 80px;
  //transition: .4s;

  border: 1px solid #cecece;
  margin-bottom: 15px;
  .topDiv {
    padding: 30px 25px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .info {
      .topInfo {
        display: flex;
        align-items: center;
        h2 {
          font-size: 18px;
          line-height: 20px;
          color: #000;
          font-weight: 400;
          word-break: break-word;
        }
        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #909090;
          margin-left: 10px;
          word-break: break-word;
        }
      }
      .count {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #000;
        margin-top: 10px;
        word-break: break-word;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .status {
        margin-right: 30px;
        display: inline-block;
        min-width: 110px;
        border-radius: 0;
        text-align: center;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: #fff;
        padding: 7px 30px;
      }
      .cancelled {
        background: #e05050;
      }
      .btn {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all .3s;
        svg {
          transition: all .3s;
        }
      }
      .opened {
        svg {
          transform: rotateZ(180deg);
        }
      }
    }
  }
}
.bottomDiv {
  transition: max-height 0.25s ease-out;
  max-height: 0;
  overflow-y: hidden;
  h2 {
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    color: #000;
    margin-bottom: 5px;
    word-break: break-word;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #979797;
    word-break: break-word;
  }
  hr {
    margin-bottom: 20px;
  }
  .topXui {
    display: flex;
    margin-bottom: 25px;
    margin-left: 20px;
    div {
      &:last-child {
        margin-left: 20px;
      }
    }

  }
}
.bott {
  margin-left: 20px;
  margin-bottom: 20px;
}
.botOpen {
  max-height: 400px;
  transition: max-height 0.25s ease-in;
}

@media (max-width: 1200px) {
  .purchase {
    width: 100%;
  }
  .topInfo {
    flex-direction: column;
    h2 {
      font-size: 14px !important;
    }
    p {
      margin-top: 5px;
      margin-left: 0px !important;
    }
  }
  .right {
    margin-left: 10px;
  }
  .item {
    margin: 10px;
    overflow: hidden;
    width: 94%;
  }
}