.reviews {
    display: flex;
    width: 100vw;
    min-height: 80vh;
    flex-direction: column;
    align-items: center;
    h1 {
        font-weight: 200;
        color: #212529;
        word-break: break-word;
        font-size: 48px;
        letter-spacing: 2.4px;
        margin: 34px 0 40px 0;
    }
    button {
        margin-top: 20px;
        margin-bottom: 20px;
        align-self: center;
        width: 150px;
        cursor: pointer;
        height: 40px;
        border: none;
        color: white;
        background-color: #000000;
        transition: .2s;
        //padding: 7px;
        &:hover {
            background-color: rgba(38, 38, 38, 1);
        }
    }
}