.modal {
  opacity: 0;
  top: 0;
  position: fixed;
  z-index: 9999;
  pointer-events: none;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(0,0,0,0.3);
  transition: 0.5s;
}
.opened {
  opacity: 1;
  pointer-events: all;
}
.titleReqs {
  margin-top: 20px;
}
.addProduct {
  margin-top: 30px;
  button {
    width: 200px;
    height: 60px;
    outline: none;
    border: none;
    border-radius: 5px;
    background-color: black;
    color: white;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    transition: .1s;
  }
  button:hover {
    background-color: #343434;
  }
  margin-bottom: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 400px;
  min-height: 400px;
  padding: 10px;
}
.items {
  margin-top: 25px;
  .item {
    margin-top: 15px;
    display: flex;

    div {
      width: 100%;
    }

    input {
      height: 30px;
      padding: 5px;
      outline: none;
      margin-left: 15px;
      font-size: 18px;
    }
  }
}