.modalBg {
  background-color: rgba(0, 0, 0, 0.37);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: .5s;
}
.active {
  opacity: 1;
  pointer-events: all;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  min-height: 360px;
  padding: 60px 50px;
  background-color: white;
  box-shadow: 3px 3px 20px 0 rgba(0,0,0,.2);
  border-radius: 4px;
  h1 {
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 24px;
  }
  .inputDiv {
    display: flex;
    input {
      height: 50px;
      color: #212529;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid rgba(0,0,0,.3);
      background: #fff;
      box-shadow: none;
      border-radius: 0;
      padding: 20px;
      outline: none;
      -webkit-transition: all .1s;
      transition: all .1s;
      &:focus {
        border: 1px solid rgba(0,0,0,.5);
      }
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #3b3b3b;
      color: white;
      padding: 0 15px;
    }
  }
  .variants {
    div {
      cursor: pointer;
      text-transform: uppercase;
      margin: 15px 10px 0 0px;
      font-size: 16px;
      padding: 10px 15px;
      -webkit-transition: .3s;
      transition: .3s;
      border-radius: 0;
      border: 1px solid rgba(0,0,0,.3);
      background: #fff;
    }
    .active {
      border-color: #f34f3f!important;
    }
  }
  button {
    outline: none;
    border: 0;
    margin-top: 20px;
    width: 93%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    height: 50px;
    font-size: 16px;
    //margin-top: 20px;
    color: #fff;
    padding: 5px;
    background: #3b3b3b;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    line-height: 1;
    font-weight: 500;
    min-height: 44px;
    text-align: center;
    align-items: center;
    &:hover {
      background: #292929;
    }
  }
}