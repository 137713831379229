.header {
    display: flex;
    height: 100px;
    width: 96vw;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e2e2e2;
    img {
        width: 80px;
        height: 80px;
        margin-left: 50px;
    }
    .links {
        display: flex;
        padding: 0 20px;
        div {
            margin-right: 35px;
            &:last-child {
                margin-right: 0px;
            }
        }
        a {
            text-decoration: none !important;
            position: relative;
            font-size: 12px;
            font-weight: 400;
            color: black !important;
            outline-width: 0;
            cursor: pointer;
            &:hover {
                &::before {
                    display: block;
                }
            }
            &::before {
                content: "";
                width: 100%;
                height: 1px;
                position: absolute;
                background: #000;
                bottom: -1px;
                left: 0;
                display: none;
            }
            
        }
        @media (max-width: 1000px) {
            display: none;
        }
    }
    .login {
        cursor: pointer;
        display: flex;
        h2 {
            width: auto;
            margin-right: 50px;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
        }
        p {
            width: auto;
        }
        .profile {
            display: flex;
            align-items: center;
            .leftDiv {
                margin-right: 0;
                display: flex;
                flex-direction: column;
                h2 {
                    margin-right: 0;
                }
            }
            .arrow {
                border-radius: 6px;
                padding: 6px;
                border-bottom: 1px solid black;
                &:hover {
                    background-color: rgba(0, 0, 0, 0.21);
                }
            }
        }
        div {
            display: flex;
            margin-right: 35px;
            &:last-child {
                margin-right: 35px;
            }
        }
        @media (max-width: 1000px) {
            display: none;
        }
    }
}
.burgerButton {
    display: none;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    font-size: 32px;
    @media (max-width: 1000px) {
        display: block;
    }
}