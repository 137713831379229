.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
  z-index: 1500;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;

  .modalContent {
    background: white;
    border-radius: 5px;
    z-index: 201;
    min-width: 1000px;
    padding: 20px;
    display: flex;

    .leftContainer {
      width: 23%;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .imgDiv {
        display: flex;

        img {
          max-width: 250px;
          max-height: 250px;
        }
      }

      .priceDiv {
        display: flex;
        align-self: flex-end;

        p {
          font-size: 16px;
        }
      }
    }

    .productInfo {
      margin-left: 30px;
      width: 100%;

      .promo {
        display: flex;
        align-items: center;
        margin-top: 15px;
        input {
          width: 100%;
          text-transform: uppercase;
          font-size: 14px;
          height: 50px;
          color: #212529;
          font-size: 16px;
          font-weight: 400;
          border: 1px solid rgba(0, 0, 0, .3);
          background: #fff;
          box-shadow: none;
          border-radius: 0;
          padding: 20px;
          outline: none;
          -webkit-transition: all .1s;
          transition: all .1s;
        }
        input:focus {
          border: 1px solid rgba(0, 0, 0, .5);
        }
        .promoBtn {
          margin-bottom: 15px;
          height: 50px;
          width: 40%;
        }
      }
      .info {
        h2 {
          margin-bottom: 30px;
          font-weight: 400;
          font-size: 24px;
        }

        h3 {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 20px;
          word-break: break-word;
        }

        p {
          margin-top: 15px;
          color: #000;
          font-weight: 400;
          font-size: 14px;
          margin-bottom: 15px;
          word-break: break-word;

          //min-height: 160px;
        }
      }


      .typeOfPayment {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-start;
        margin: 0 -10px;
        margin-top: 50px;
        max-width: 270px;

        p {
          margin-left: 10px;
        }

        div {
          text-transform: uppercase;
          //margin: 15px 10px 0 10px;
          font-size: 16px;
          margin-top: 10px;
          padding: 10px 15px;
          transition: .3s;
          border-radius: 0;
          border: 1px solid rgba(0, 0, 0, .3);
          background: #fff;
          cursor: pointer;
          margin-left: 10px;
        }

        div:hover {
          border-color: #f34f3f;
        }
        .active {
          border-color: #f34f3f;
        }
      }
      button {
        color: #fff;
        background: #3b3b3b;
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        font-size: 16px;
        line-height: 1;
        width: 100%;
        font-weight: 500;
        min-height: 44px;
        text-align: center;
        display: -ms-inline-flexbox;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: none;
        outline: none;
        cursor: pointer;
        margin-top: 15px;
      }
    }
  }
}

.active1 {
  opacity: 1;
  pointer-events: all;
}

@media (max-width: 768px) {
  .modalContent {
    width: 97% !important;
    justify-content: center;
    align-items: center;
    min-width: 0px !important;
  }
  .imgDiv, .priceDiv, .leftContainer {
    display: none !important;
  }
  .promoBtn {
    width: 100% !important;
  }
}