.home {
  // display: flex;
  // width: 100vw;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // margin-top: 15px;

  .firstBlock {
    display: flex;
    width: 100vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;

    h1 {
      font-weight: 200;
      color: #212529;
      word-break: break-word;
      font-family: Oswald;
      font-size: 48px;
      letter-spacing: 2.4px;
      margin: 34px 0 40px 0;
    }

    .banner {
      background-image: url("https://i.ibb.co/Vg8MvqG/image.jpg");
      width: 1170px;
      object-fit: cover;
      height: 200px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 64px;
      position: relative;
      @media (max-width: 1170px) {
        width: 95vw;
      }
    }
  }

  .categoryInfo {
    max-width: 1230px;
    padding: 0 30px;
    margin: 0 auto;
    display: flex;
  }

  .inputs {
    max-width: 1230px;
    padding: 0 30px;
    margin: 0 auto;
    display: flex;
    margin-bottom: 15px;

    .btnFilter {
      color: #fff;
      //margin-left: 20px;
      height: 56px;
      cursor: pointer;
      width: 140px;
      background: #3b3b3b;
      transition: all .3s ease-in-out;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      padding: 16px 31px;
      border: none;
      outline: none;
    }

    .btnFilter:hover {
      background: #292929;
    }

    .section {
      //margin-left: 10px;
      .ul {
        list-style: none;
        margin: 0;
        padding: 0;
        //max-height: 200px;
        overflow-y: auto;

        .li {
          color: black;
          padding: 10px 20px;
          cursor: pointer;
          word-break: break-word;
          border: 1px solid black;
          border-top: none;
          transition: .1s;
        }

        .li:hover {
          background: #292929 !important;
          color: #fff;

        }

      }
    }

    .section:not(:first-child) {
      margin-left: 20px;
    }

    .sectionDiv {
      display: flex;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, .5);
      -webkit-transition: all .3s ease;
      transition: all .3s ease;
      height: 54px;
      //line-height: 54px;
      padding: 0 30px 0 20px;
      font-size: 14px;
      font-weight: 500;
      color: #212529;

      .btn {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all .3s;

        svg {
          transition: all .3s;
          height: 20px;
          width: 20px;
        }
      }

      .opened {
        svg {
          transform: rotateZ(180deg);
        }
      }
    }
  }

  .items {
    max-width: 1230px;
    padding: 0 30px;
    margin: 0 auto;
    min-height: 600px;

    h2 {
      font-size: 32.5px;
      font-weight: 200;
      color: #212529;
      word-break: break-word;
      font-family: Oswald;
      letter-spacing: 2.4px;
      margin-bottom: 40px;
      margin-top: 15px;
    }

    .containerInner {
      display: flex;
      flex-wrap: wrap;

      .productsContentItem:not(:first-child) {
        margin-left: 20px;
      }

      .productsContentItem {
        justify-content: space-between;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        width: 23%;
        margin: 0 0 60px 0;
        margin-top: 10px;
        padding: 10px;
        border: 1px solid #e2e2e2;

        .productsItemName {
          word-break: break-word;
          font-size: 18px;
          color: #000;
          min-height: 40px;
          transition: .1s;
          user-select: none;
        }

        .productsItemName:hover {
          color: #ff240b;
        }

        .productsItemInfo {
          p {
            font-size: 21px;
            margin-top: 23px;
            margin-bottom: 10px;
            text-transform: uppercase;
          }

          button {
            padding: 15px 30px;
            background: #fff;
            width: 100%;
            line-height: 1;
            color: #2d2d2d;
            font-size: 16px;
            font-weight: 500;
            border: 1px solid #303030;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .3s ease-in-out;
            cursor: pointer;
          }

          button:hover {
            background-color: #3b3b3b;
            color: #fff;
          }
        }
      }
    }
  }

}
.changeCity {
  width: 170px !important;
  padding: 5px !important;
}

.inf {
  display: flex;
}

.btnFilter:not(:first-child) {
  margin-left: 30px;
}

@media (max-width: 768px) {
  .hid {
    display: none !important;
  }
  .sectionDiv {
    height: 56px !important;
    width: 200px !important;
    //line-height: 0px !important;
  }
  .inputs {
    flex-direction: column;
  }
  .btnFilter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    margin-left: 0px !important;
  }
  .li {
    width: 200.34px;
  }
  .containerInner {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    align-items: center;

    .productsContentItem {
      width: 90% !important;
    }
  }
  .inputs {
    padding: 7px !important;
  }
  .section:not(:first-child) {
    margin-left: 0px !important;
  }
}