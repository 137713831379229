
.wrapp {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 30px;
  .productContainer {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .btnsDiv {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      button {
        font-size: 12px;
        margin-top: 10px;
        width: 150px;
        height: 40px;
        outline: none;
        border: none;
        border-radius: 5px;
        background-color: #262626;
        color: white;
        padding: 10px;
        cursor: pointer;
        font-weight: bold;
        transition: .1s;
      }
      .delete {
        background-color: #c20000;
      }
      .delete:hover {
        background-color: #d00000;
      }
    }

    h3 {
      margin-bottom: 10px;
      font-size: 18px;
    }

    .productInfo {
      display: flex;
      flex-direction: column;

      img {
        max-width: 100%;
        height: auto;
        border-radius: 8px;
        margin-bottom: 10px;
      }

      p {
        margin: 0;
      }

      .title {
        font-weight: bold;
      }

      .category {
        margin-top: 5px;
      }

      .gramm {
        margin-top: 5px;
      }

      .price {
        margin-top: 5px;
        //font-weight: bold;
      }
    }
  }

}
