.Footer {
    display: flex;
    width: 100vw;
    height: 100px;
    justify-content: center;
    border-top: 1px solid gray;
    align-items: center;
    h1 {
        color: rgb(85, 85, 85);
        font-size: 16px;
        text-align: center;
        margin: 7px;
    }
}