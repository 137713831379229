// .reviews {
//     display: flex;
//     width: 100vw;
//     flex-direction: column;
//     align-items: center;
//     h1 {
        
//         font-weight: 200;
//         color: #212529;
//         word-break: break-word;
//         font-family: Oswald;
//         font-size: 48px;
//         letter-spacing: 2.4px;
//         margin: 34px 0 40px 0;
//     }
// }
.comments {
    display: flex;
    flex-direction: column;

    .list {
        display: flex;
        width: 100vw;
        align-items: center;
        flex-direction: column;
    }
    button {
        margin-top: 20px;
        margin-bottom: 20px;
        align-self: center;
        width: 150px;
        cursor: pointer;
        height: 40px;
        border: none;
        color: white;
        background-color: #000000;
        transition: .2s;
        //padding: 7px;
        &:hover {
            background-color: rgba(38, 38, 38, 1);
        }
    }
}